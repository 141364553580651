import React, { useState, useCallback, useEffect } from "react";
import Header from "../components/header/Header";
import SolMenu from "../components/header/solmenu/Solmenu";
import { Form, Input, Select, DatePicker, InputNumber, Button } from "antd";
import { useParams } from "react-router-dom";
import config from "../components/header/config";
import moment from "moment"; // Import moment
import { useNavigate } from "react-router-dom";

const StratejikHedefGuncelle = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [departmanlar, setDepartmanlar] = useState([]);
  const [kpilar, setKpilar] = useState([]);
  const [hedefturleri, setHedefTurleri] = useState([]);
  const [sorumlular, setSorumlular] = useState([]);
  const [aksiyonlar, setAksiyonlar] = useState([]);
  const [value, setValue] = useState([]);
  const [modalForm] = Form.useForm();

  const fetchData = useCallback(async (url, setData) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      const adlar = data.map((da) => da.ad);
      setData(adlar);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  useEffect(() => {
    fetchData(`${config.API_URL}/list/departman`, setDepartmanlar);
    fetchData(`${config.API_URL}/list/kpi`, setKpilar);
    fetchData(`${config.API_URL}/list/sorumlu`, setSorumlular);
    fetchData(`${config.API_URL}/list/hedefturu`, setHedefTurleri);
  }, [fetchData]);

  const fetchStratejik = useCallback(
    async (url, setData) => {
      try {
        const response = await fetch(url);
        const data = await response.json();
        const filteredData = data.filter((item) => item._id === id);
        setValue(filteredData[0]);
        console.log(filteredData[0]);
        form.setFieldsValue({
          aksiyon: filteredData[0].aksiyon,
          bolum: filteredData[0].bolum,
          departman: filteredData[0].departman,
          hedef_turu: filteredData[0].hedef_turu,
          date: moment(filteredData[0].date),
          hedef_yuzde: filteredData[0].hedef_yuzde,
          kpi: filteredData[0].kpi,
          maliyet: filteredData[0].maliyet,
          mevcut_durum: filteredData[0].mevcut_durum,
          mevcut_durum_aciklama: filteredData[0].mevcut_durum_aciklama,
          sorumlu: filteredData[0].sorumlu,
          status: filteredData[0].status,
        });
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [id, form]
  );

  useEffect(() => {
    fetchStratejik(`${config.API_URL}/list/stratejikhedef`, setValue);
  }, [fetchStratejik]);

  const handleSubmit = async (values) => {
    const strathedef = {
      ...values,
      status: "0",
      date: values.date.format("YYYY-MM-DD"),
    };

    try {
      const response = await fetch(`${config.API_URL}/updatestrathedef/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ strathedef }),
      });

      if (response.ok) {
        console.log("Data saved successfully");
        navigate(`/Bildirimler`);
      } else {
        console.error("Failed to save data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div>
        <Header> </Header>
      </div>
      <div className="Anasayfa flex justify-normal">
        <div className="SolMenu">
          <div>
            <SolMenu></SolMenu>
          </div>
        </div>

        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical px-3"
          className="w-full"
        >
          <Form.Item
            label="KPI"
            name="kpi"
            rules={[{ required: true, message: "Lütfen KPI Giriniz" }]}
          >
            <Select placeholder="KPI Giriniz">
              {kpilar.map((kpi, i) => (
                <Select.Option key={i} value={kpi}>
                  {kpi}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Departman"
            name="departman"
            rules={[{ required: true, message: "Lütfen Departman Giriniz" }]}
          >
            <Select placeholder="Departman Giriniz">
              {departmanlar.map((departman, i) => (
                <Select.Option key={i} value={departman}>
                  {departman}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Hedef Türü"
            name="hedef_turu"
            rules={[{ required: true, message: "Lütfen Hedef Türü Giriniz" }]}
          >
            <Select placeholder="Hedef Türü Giriniz">
              {hedefturleri.map((hedef, i) => (
                <Select.Option key={i} value={hedef}>
                  {hedef}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Mevcut Durum"
            name="mevcut_durum"
            rules={[{ required: true, message: "Lütfen Mevcut Durum Giriniz" }]}
          >
            <Input placeholder="Mevcut Durum Giriniz" />
          </Form.Item>

          <Form.Item
            label="Hedef Yüzdesi"
            name="hedef_yuzde"
            rules={[
              { required: true, message: "Lütfen Hedef Yüzdesi Giriniz" },
            ]}
          >
            <InputNumber
              placeholder="Hedef Yüzdesi Giriniz"
              className="w-full"
            />
          </Form.Item>

          <Form.Item
            label="Mevcut Durum Açıklama"
            name="mevcut_durum_aciklama"
            rules={[
              {
                required: true,
                message: "Lütfen Mevcut Durum Açıklama Giriniz",
              },
            ]}
          >
            <Input placeholder="Mevcut Durum Açıklama Giriniz" />
          </Form.Item>

          <Form.Item
            label="Aksiyon Planı"
            name="aksiyon"
            rules={[
              {
                required: true,
                message: "Lütfen Aksiyon Planı Giriniz",
              },
            ]}
          >
            <Input placeholder="Aksiyon Planı Giriniz" className="w-full" />
          </Form.Item>

          <Form.Item
            label="Sorumlu"
            name="sorumlu"
            rules={[
              {
                required: true,
                message: "Lütfen Sorumlu Giriniz",
              },
            ]}
          >
            <Select placeholder="Sorumlu Giriniz" className="w-full">
              {sorumlular.map((sorumlu, i) => (
                <Select.Option key={i} value={sorumlu}>
                  {sorumlu}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Maliyet"
            name="maliyet"
            rules={[
              {
                required: true,
                message: "Lütfen Maliyet Giriniz",
              },
            ]}
          >
            <InputNumber placeholder="Maliyet Giriniz" className="w-full" />
          </Form.Item>

          <Form.Item
            label="Termin Tarihi"
            name="date"
            rules={[
              {
                required: true,
                message: "Lütfen Termin Tarihi Giriniz",
              },
            ]}
          >
            <DatePicker
              placeholder="Termin Tarihi Giriniz"
              className="w-full"
              format="YYYY-MM-DD"
            />
          </Form.Item>
          <Button htmlType="submit" type="primary">
            Kaydet
          </Button>
        </Form>
      </div>
    </>
  );
};

export default StratejikHedefGuncelle;
