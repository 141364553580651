import React from "react";
import Header from "../components/header/Header";
import SolMenu from "../components/header/solmenu/Solmenu";
import { Form, Input, Button, Select } from "antd";
import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import config from "../components/header/config";

const KontrolNoktalariGuncelle = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [value, setValue] = useState("");
  const [departmanlar, setDepartmanlar] = useState([]);

  const fetchData = useCallback(
    async (url, setData) => {
      try {
        const response = await fetch(url);
        const data = await response.json();
        const filteredData = data.filter((item) => item._id === id);
        setValue(filteredData[0]);
        form.setFieldsValue({
          departman: filteredData[0].departman,
          tehlike: filteredData[0].tehlike,
          agirlik: filteredData[0].agirlik,
          durum: filteredData[0].durum,
          puan: filteredData[0].puan,
          aciklama: filteredData[0].aciklama,
        });
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [id, form]
  );

  useEffect(() => {
    fetchData(`${config.API_URL}/list/kontrolnoktasi`, setValue);
  }, [fetchData]);

  const fetchSelect = useCallback(async (url, setData) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      const adlar = data.map((da) => da.ad);
      setData(adlar);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  useEffect(() => {
    fetchSelect(`${config.API_URL}/list/departman`, setDepartmanlar);
  }, [fetchData]);

  const handleSubmit = async (values) => {
    const updated = values;
    try {
      const response = await fetch(`${config.API_URL}/updatekontrol/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updated),
      });
      if (response.ok) {
        console.log("Data saved successfully");
        navigate("/KontrolNoktalari");
      } else {
        console.error("Failed to save data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleValuesChange = (changedValues, allValues) => {
    const { agirlik, durum } = allValues;
    if (agirlik && durum) {
      if (agirlik > 50) {
        const puan = 50 * durum;
        form.setFieldsValue({ puan });
      } else {
        const puan = agirlik * durum;
        form.setFieldsValue({ puan });
      }
    }
  };

  return (
    <>
      <div>
        <Header />
      </div>
      <div className="Anasayfa flex justify-normal">
        <div className="SolMenu">
          <SolMenu />
        </div>
        <div className="px-6 py-6 flex flex-col flex-grow">
          <Form
            form={form}
            onFinish={handleSubmit}
            onValuesChange={handleValuesChange}
            layout="vertical"
            className="w-full px-3"
          >
            <Form.Item
              label="Departman"
              name="departman"
              rules={[{ required: true, message: "Lütfen Departman Giriniz" }]}
            >
              <Select placeholder="Departman Giriniz">
                {departmanlar.map((departman, i) => (
                  <Select.Option key={i} value={departman}>
                    {departman}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Kontrol Noktası"
              name="tehlike"
              rules={[
                {
                  required: true,
                  message: "Lütfen Kontrol Noktası Giriniz",
                },
              ]}
            >
              <Input
                placeholder="Kontrol Noktası Giriniz"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  form.setFieldsValue({ tehlike: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Ağırlık"
              name="agirlik"
              rules={[
                {
                  required: true,
                  message: "Lütfen Ağırlık Giriniz",
                },
              ]}
            >
              <Input
                placeholder="Ağırlık Giriniz"
                value={value}
                onChange={(e) => {
                  let inputValue = e.target.value;
                  // If the input value is greater than 50, set it to 50
                  if (inputValue > 50) {
                    inputValue = 50;
                  }
                  setValue(inputValue);
                  form.setFieldsValue({ agirlik: inputValue });
                }}
              />
            </Form.Item>

            <Form.Item
              label="Durum"
              name="durum"
              rules={[
                {
                  required: true,
                  message: "Lütfen Durum Giriniz",
                },
              ]}
            >
              <Select placeholder="Durum Giriniz">
                <Select.Option value="0">0</Select.Option>
                <Select.Option value="1">1</Select.Option>
                <Select.Option value="2">2</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Puan"
              name="puan"
              rules={[
                {
                  required: true,
                  message: "Lütfen Puan Giriniz",
                },
              ]}
            >
              <Input placeholder="Puan Giriniz" value={value} readOnly />
            </Form.Item>
            <Form.Item
              label="Açıklama"
              name="aciklama"
              rules={[
                {
                  message: "Lütfen Açıklama Giriniz",
                },
              ]}
            >
              <Input
                placeholder="Açıklama Giriniz"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  form.setFieldsValue({ aciklama: e.target.value });
                }}
              />
            </Form.Item>

            <Button htmlType="submit" type="primary">
              Kaydet
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default KontrolNoktalariGuncelle;
