import React, { useState, useCallback, useEffect } from "react";
import Header from "../components/header/Header";
import SolMenu from "../components/header/solmenu/Solmenu";
import {
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Button,
  Modal,
  Table,
  message,
} from "antd";
import config from "../components/header/config";

const generateRandomKey = () => {
  const randomString = () =>
    Math.random().toString(36).substring(2, 6).toUpperCase();
  return `KEY-${randomString()}-${randomString()}`;
};

const checkKeyExists = async (key) => {
  try {
    const response = await fetch(`${config.API_URL}/checkKeyExists`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ key }),
    });

    if (!response.ok) {
      throw new Error(`Sunucu hatası: ${response.statusText}`);
    }

    const data = await response.json();
    return data.exists;
  } catch (error) {
    console.error("Anahtar kontrol hatası:", error);
    return false;
  }
};

const StratejikHedef = () => {
  const [form] = Form.useForm();
  const [departmanlar, setDepartmanlar] = useState([]);
  const [bolumler, setbolumler] = useState([]);
  const [kpilar, setKpilar] = useState([]);
  const [hedefturleri, setHedefTurleri] = useState([]);
  const [sorumlular, setSorumlular] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [aksiyonlar, setAksiyonlar] = useState([]);
  const [modalForm] = Form.useForm();

  const [selectedKpi, setSelectedKpi] = useState("");
  const [mevcutDurum, setMevcutDurum] = useState("");
  const [generatedKey, setGeneratedKey] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
    generateAndCheckKey(); // Modal açıldığında anahtar üret ve kontrol et
  };

  const generateAndCheckKey = async () => {
    let key;
    let keyExists = true;

    while (keyExists) {
      key = generateRandomKey();
      keyExists = await checkKeyExists(key); // Anahtarın daha önce kullanılıp kullanılmadığını kontrol et
    }

    setGeneratedKey(key); // Kullanılmamış anahtar bulunursa set et
    modalForm.setFieldsValue({ key }); // Form içinde key alanını doldur
  };

  const handleOk = () => {
    modalForm
      .validateFields()
      .then((values) => {
        const aksiyon = {
          ...values,
          date: values.date.format("YYYY-MM-DD"),
        };
        setAksiyonlar([...aksiyonlar, aksiyon]);
        modalForm.resetFields();
        setIsModalOpen(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const fetchData = useCallback(async (url, setData) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  useEffect(() => {
    fetchData(`${config.API_URL}/list/departman`, setDepartmanlar);
    fetchData(`${config.API_URL}/list/bolum`, setbolumler);
    fetchData(`${config.API_URL}/list/kpi`, setKpilar);
    fetchData(`${config.API_URL}/list/sorumlu`, setSorumlular);
    fetchData(`${config.API_URL}/list/hedefturu`, setHedefTurleri);
  }, [fetchData]);

  const handleSubmit = async (values) => {
    for (const aksiyon of aksiyonlar) {
      const strathedef = { ...values, ...aksiyon, status: "0" };
      try {
        const response = await fetch(`${config.API_URL}/addstratejik`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(strathedef),
        });

        if (response.ok) {
          console.log("Data saved successfully");
          form.resetFields();
          setAksiyonlar([]);
        } else {
          console.error("Failed to save data");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleKpiChange = (value) => {
    const kpi = kpilar.find((item) => item.ad === value);
    setSelectedKpi(kpi);
    setMevcutDurum(kpi ? kpi.mevcut_durum : null);
    form.setFieldsValue({ mevcut_durum: kpi ? kpi.mevcut_durum : "" });
  };

  const columns = [
    { title: "Aksiyon Planı", dataIndex: "aksiyon", key: "aksiyon" },
    { title: "Sorumlu", dataIndex: "sorumlu", key: "sorumlu" },
    { title: "Maliyet", dataIndex: "maliyet", key: "maliyet" },
    { title: "Termin Tarihi", dataIndex: "date", key: "date" },
    { title: "Key", dataIndex: "key", key: "key" },
  ];

  return (
    <>
      <Header />
      <div className="Anasayfa flex justify-normal">
        <SolMenu />
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical px-3"
          className="w-full"
        >
          <Form.Item
            label="KPI"
            name="kpi"
            rules={[{ required: true, message: "Lütfen KPI Giriniz" }]}
          >
            <Select placeholder="KPI Giriniz" onChange={handleKpiChange}>
              {kpilar.map((kpi, i) => (
                <Select.Option key={i} value={kpi.ad}>
                  {kpi.ad}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Departman"
            name="departman"
            rules={[{ required: true, message: "Lütfen Departman Giriniz" }]}
          >
            <Select placeholder="Departman Giriniz">
              {departmanlar.map((departman, i) => (
                <Select.Option key={i} value={departman.ad}>
                  {departman.ad}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Bolum"
            name="bolum"
            rules={[{ required: true, message: "Lütfen Bolüm Giriniz" }]}
          >
            <Select placeholder="Bölüm Giriniz">
              {bolumler.map((bolum, i) => (
                <Select.Option key={i} value={bolum.ad}>
                  {bolum.ad}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>


          <Form.Item
            label="Hedef Türü"
            name="hedef_turu"
            rules={[{ required: true, message: "Lütfen Hedef Türü Giriniz" }]}
          >
            <Select placeholder="Hedef Türü Giriniz">
              {hedefturleri.map((hedef, i) => (
                <Select.Option key={i} value={hedef.ad}>
                  {hedef.ad}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Mevcut Durum" name="mevcut_durum">
            <Input value={mevcutDurum} readOnly />
          </Form.Item>

          <Form.Item
            label="Hedef Yüzdesi"
            name="hedef_yuzde"
            rules={[
              { required: true, message: "Lütfen Hedef Yüzdesi Giriniz" },
            ]}
          >
            <InputNumber
              placeholder="Hedef Yüzdesi Giriniz"
              className="w-full"
            />
          </Form.Item>

          <Form.Item
            label="Mevcut Durum Açıklama"
            name="mevcut_durum_aciklama"
            rules={[
              {
                required: true,
                message: "Lütfen Mevcut Durum Açıklama Giriniz",
              },
            ]}
          >
            <Input placeholder="Mevcut Durum Açıklama Giriniz" />
          </Form.Item>

          <Button type="primary" onClick={showModal}>
            Aksiyon Planı Ekle
          </Button>

          <Table dataSource={aksiyonlar} columns={columns} rowKey="aksiyon" />

          <Modal
            title="Aksiyon Planı"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Form form={modalForm} layout="vertical" className="w-full">
              <Form.Item label="Anahtar" name="key">
                <Input value={generatedKey} readOnly />
              </Form.Item>

              <Form.Item
                label="Aksiyon Planı"
                name="aksiyon"
                rules={[
                  { required: true, message: "Lütfen Aksiyon Planı Giriniz" },
                ]}
              >
                <Input placeholder="Aksiyon Planı Giriniz" className="w-full" />
              </Form.Item>

              <Form.Item
                label="Sorumlu"
                name="sorumlu"
                rules={[{ required: true, message: "Lütfen Sorumlu Giriniz" }]}
              >
                <Select placeholder="Sorumlu Giriniz" className="w-full">
                  {sorumlular.map((sorumlu, i) => (
                    <Select.Option key={i} value={sorumlu.ad}>
                      {sorumlu.ad}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label="Maliyet"
                name="maliyet"
                rules={[{ required: true, message: "Lütfen Maliyet Giriniz" }]}
              >
                <InputNumber placeholder="Maliyet Giriniz" className="w-full" />
              </Form.Item>

              <Form.Item
                label="Termin Tarihi"
                name="date"
                rules={[
                  { required: true, message: "Lütfen Termin Tarihi Giriniz" },
                ]}
              >
                <DatePicker
                  placeholder="Termin Tarihi Giriniz"
                  className="w-full"
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Form>
          </Modal>

          <Button htmlType="submit" type="primary">
            Kaydet
          </Button>
        </Form>
      </div>
    </>
  );
};

export default StratejikHedef;
