import React, { useState } from "react";
import Header from "../components/header/Header";
import OrtaMenu from "../components/header/ortamenu/OrtaMenu";
import SolMenu from "../components/header/solmenu/Solmenu";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
export const Anasayfa = () => {
  const navigate = useNavigate();
  const [id, setID] = useState("");
  const [ad, setAd] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    setID(localStorage.getItem("id"));
    setAd(localStorage.getItem("ad"));
    console.log(id);
    setID(id);
    if (!token) {
      navigate("/");
    }
  }, []);

  return (
    <>
      <div>
        <Header />
      </div>
      <div className="Anasayfa flex justify-normal">
        <div className="SolMenu">
          <div>
            <SolMenu />
          </div>
        </div>
        <div className="OrtaMenu w-full flex flex-col items-center">
          <div>
            <OrtaMenu />
          </div>
          <div className="text-center mt-4">{ad} Hoş Geldiniz</div>
        </div>
      </div>
    </>
  );
};
