import React from "react";
import Header from "../../components/header/Header";
import SolMenu from "../../components/header/solmenu/Solmenu";
import { Form, Input, Button, Select } from "antd";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../components/header/config";

const SorumluEkle = () => {
  const [form] = Form.useForm();
  const [value, setValue] = useState("");
  const [departmanlar, setDepartmanlar] = useState([]);
  const [unvanlar, setUnvanlar] = useState([]);
  const navigate = useNavigate();
  const handleSubmit = async (values) => {
    console.log(values);
    try {
      const response = await fetch(`${config.API_URL}/addSorumlu`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      if (response.ok) {
        console.log("Data saved successfully");
        navigate("/Sorumlu");
      } else {
        console.error("Failed to save data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchData = useCallback(async (url, setData) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      const adlar = data.map((da) => da.ad);
      setData(adlar);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  useEffect(() => {
    fetchData(`${config.API_URL}/list/departman`, setDepartmanlar);
    fetchData(`${config.API_URL}/list/unvan`, setUnvanlar);
  }, [fetchData]);

  return (
    <>
      <div>
        <Header />
      </div>
      <div className="Anasayfa flex justify-normal">
        <div className="SolMenu">
          <SolMenu />
        </div>
        <div className="px-6 py-6 flex flex-col flex-grow">
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            className="w-full px-3"
          >
            <Form.Item
              label="Sorumlu Ad"
              name="ad"
              rules={[
                {
                  required: true,
                  message: "Lütfen Sorumlu Ad Giriniz",
                },
              ]}
            >
              <Input
                placeholder="Sorumlu Ad Giriniz"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  form.setFieldsValue({ ad: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Sorumlu Departman"
              name="departman"
              rules={[{ required: true, message: "Lütfen Departman Giriniz" }]}
            >
              <Select placeholder="Departman Giriniz">
                {departmanlar.map((departman, i) => (
                  <Select.Option key={i} value={departman}>
                    {departman}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Sorumlu Unvan"
              name="unvan"
              rules={[
                {
                  required: true,
                  message: "Lütfen Sorumlu Unvan Giriniz",
                },
              ]}
            >
              <Input
                placeholder="Sorumlu Unvan Giriniz"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  form.setFieldsValue({ unvan: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Sorumlu Şifre"
              name="sifre"
              rules={[
                {
                  required: true,
                  message: "Lütfen Sorumlu Şifre Giriniz",
                },
              ]}
            >
              <Input
                placeholder="Sorumlu Şifre Giriniz"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  form.setFieldsValue({ sifre: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Sorumlu Rol"
              name="rol"
              rules={[
                {
                  required: true,
                  message: "Lütfen Sorumlu Rol Giriniz",
                },
              ]}
            >
              <Select placeholder="Sorumlu Rol Giriniz">
                <Select.Option value={"A"}>A</Select.Option>
                <Select.Option value={"B"}>B</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Sorumlu Statüs"
              name="status"
              rules={[
                {
                  required: true,
                  message: "Lütfen Sorumlu Statüs Giriniz",
                },
              ]}
            >
              <Select placeholder="Sorumlu Statüs Giriniz">
                <Select.Option value={true}>True</Select.Option>
                <Select.Option value={false}>False</Select.Option>
              </Select>
            </Form.Item>
            <Button htmlType="submit" type="primary">
              Kaydet
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default SorumluEkle;
