import React from "react";
import Header from "../components/header/Header";
import SolMenu from "../components/header/solmenu/Solmenu";
import { useState, useCallback, useEffect } from "react";

import { Form, Input, Select, Button } from "antd";
import { useNavigate } from "react-router-dom";
import config from "../components/header/config";
function KontrolNoktalariEkle() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [departmanlar, setDepartmanlar] = useState([]);

  const fetchData = useCallback(async (url, setData) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      const adlar = data.map((da) => da.ad);
      setData(adlar);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  useEffect(() => {
    fetchData(`${config.API_URL}/list/departman`, setDepartmanlar);
  }, [fetchData]);

  const handleSubmit = async (values) => {
    const value = values;
    try {
      const response = await fetch(`${config.API_URL}/addkontrol`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });

      if (response.ok) {
        console.log("Data saved successfully");
        form.resetFields();
        navigate("/KontrolNoktalari");
      } else {
        console.error("Failed to save data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div>
        <Header> </Header>
      </div>
      <div className="Anasayfa  flex justify-normal">
        <div className="SolMenu">
          <div>
            <SolMenu></SolMenu>{" "}
          </div>
        </div>

        <Form
          layout="vertical px-3 "
          className="w-full"
          form={form}
          onFinish={handleSubmit}
        >
          <Form.Item
            label="Departman"
            name="departman"
            rules={[{ required: true, message: "Lütfen Departman Giriniz" }]}
          >
            <Select placeholder="Departman Giriniz">
              {departmanlar.map((departman, i) => (
                <Select.Option key={i} value={departman}>
                  {departman}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Kontrol Noktası"
            name="kontrolnoktasi"
            rules={[
              { required: true, message: "Lütfen Kontrol Noktası Giriniz" },
            ]}
          >
            <Select placeholder="Kontrol Noktası Giriniz">
              <Select.Option value="Hatalı Okuma">Hatalı Okuma</Select.Option>
              <Select.Option value="Hatalı Kesim">Hatalı Kesim</Select.Option>
              <Select.Option value="Yanlış Okuma">Yanlış Okuma</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Ağırlık"
            name="agirlik"
            rules={[{ required: true, message: "Lütfen Ağırlık Giriniz" }]}
          >
            <Input placeholder="Ağırlık Giriniz" />
          </Form.Item>
          <Form.Item
            label="Durum"
            name="durum"
            rules={[{ required: true, message: "Lütfen Durum Giriniz" }]}
          >
            <Input placeholder="Durum Giriniz" className="w-full" />
          </Form.Item>
          <Form.Item
            label="Puan"
            name="puan"
            rules={[{ required: true, message: "Lütfen Puan Giriniz" }]}
          >
            <Input placeholder="Puan Giriniz" />
          </Form.Item>

          <Form.Item
            label="Açıklama"
            name="aciklama"
            rules={[{ required: true, message: "Lütfen Açıklama Giriniz" }]}
          >
            <Input placeholder="Açıklama Giriniz" />
          </Form.Item>

          <Button type="primary" htmlType="submit">
            Kaydet
          </Button>
        </Form>
      </div>
    </>
  );
}

export default KontrolNoktalariEkle;
