import React, { useState, useEffect } from "react";
import {
  UnorderedListOutlined,
  FundOutlined,
  DotChartOutlined,
  DollarOutlined,
  CopyOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";

const { Sider } = Layout;

function getItem(label, key, icon, link, children) {
  return {
    key,
    icon,
    children,
    label: link ? (
      <Link
        to={link}
        className="menu-link flex flex-col hover:text-[#40a9ff] transition-all"
      >
        {label}
      </Link>
    ) : (
      label
    ),
  };
}

const allItems = [
  getItem("Stratejik Hedef", "1", <UnorderedListOutlined />, "/StratejikHedef"),
  getItem("Risk Analizi", "2", <FundOutlined />, "/RiskAnaliz"),
  getItem("Kontrol Noktaları", "3", <DotChartOutlined />, "/KontrolNoktalari"),
  getItem("Ücret Yönetimi", "4", <DollarOutlined />, "/UcretYonetimi"),
  getItem("Sistem Altyapı", "sub1", <CopyOutlined />, undefined, [
    getItem("Departman", "5", undefined, "/Departman"),
    getItem("Sorumlu", "6", undefined, "/Sorumlu"),
    getItem("Bölüm", "7", undefined, "/Bolum"),
    getItem("KPI", "8", undefined, "/KPI"),
    getItem("Hedef Türü", "9", undefined, "/HedefTuru"),
  ]),
  getItem("Raporlar", "sub2", <SnippetsOutlined />, undefined, [
    getItem("Stratejik Hedef", "10", undefined, "/StratejikHedefRapor"),
    getItem("Risk Analiz", "11", undefined, "/RiskAnalizRapor"),
  ]),
];

const limitedItems = [
  getItem("Stratejik Hedef", "1", <UnorderedListOutlined />, "/StratejikHedef"),
  getItem("Risk Analizi", "2", <FundOutlined />, "/RiskAnaliz"),
];

const SolMenu = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [items, setItems] = useState(limitedItems); // Başlangıçta sınırlı menü öğelerini yükle
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const rol = localStorage.getItem("rol");

    if (!token) {
      navigate("/");
    }

    // Rol kontrolü
    if (rol === "A") {
      setItems(allItems); // Rol 'A' ise tüm menü öğelerini yükle
    } else {
      setItems(limitedItems); // Rol 'A' değilse sınırlı menü öğelerini yükle
    }
  }, [navigate]);

  return (
    <Layout style={{ minHeight: "200vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
          items={items}
        />
      </Sider>
    </Layout>
  );
};

export default SolMenu;
