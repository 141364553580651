

const OrtaMenu = () => {
  
  return (
    <div >

      
      </div>
    
  )
}

export default OrtaMenu