import React from "react";
import SolMenu from "../../components/header/solmenu/Solmenu";
import Header from "../../components/header/Header";
import { Table, Button } from "antd";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../components/header/config";

export const Kpi = () => {
  const [datas, setDatas] = useState([]);
  const navigate = useNavigate();

  const fetchData = useCallback(async (url, setData) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  useEffect(() => {
    fetchData(`${config.API_URL}/list/kpi`, setDatas);
  }, [fetchData]);

  const columns = [
    {
      title: "KPI AD",
      dataIndex: "ad",
      key: "ad",
      align: "center",
    },
    {
      title: "Mevcut Durum",
      dataIndex: "mevcut_durum",
      key: "mevcut_durum",
      align: "center",
    },
    {
      title: "KPI Güncelle",
      dataIndex: "guncelle",
      key: "guncelle",
      align: "center",
      render: (text, record) => (
        <Button type="primary" onClick={() => handleGuncelle(record._id)}>
          Güncelle
        </Button>
      ),
    },
  ];

  const handleGuncelle = async (id) => {
    console.log("Guncellenen id:", id);
    navigate(`/KPI/Guncelle/${id}`);
  };

  const handleEkle = async () => {
    navigate(`/KPI/Ekle`);
  };

  return (
    <>
      <div>
        <Header> </Header>
      </div>
      <div className="Anasayfa  flex justify-normal">
        <div className="SolMenu">
          <div>
            <SolMenu></SolMenu>{" "}
          </div>
        </div>
        <div className="px-6 py-6 flex flex-col flex-grow">
          <Table
            dataSource={datas}
            columns={columns}
            bordered
            pagination={false}
          />
          <div className="mt-4 flex justify-start">
            <Button type="primary" onClick={handleEkle}>
              Yeni KPI Ekle
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
