import { Table, Button, Input } from "antd";
import Header from "../components/header/Header";
import SolMenu from "../components/header/solmenu/Solmenu";
import { useState, useEffect, useCallback } from "react";
import config from "../components/header/config";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words"; // Highlighter import
import * as XLSX from "xlsx";

const RiskAnalizRapor = () => {
  const [datas, setDatas] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const fetchData = useCallback(async (url, setData) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      setDatas(data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  useEffect(() => {
    fetchData(`${config.API_URL}/list/riskanaliz`, setDatas);
  }, [fetchData]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleSil = async (id) => {
    console.log("Sil id:", id);
    try {
      const response = await fetch(`${config.API_URL}/delete/riskanaliz`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
      });

      if (response.ok) {
        console.log("Data deleted successfully");
        setDatas((prevDatas) => prevDatas.filter((item) => item._id !== id));
      } else {
        console.error("Failed to delete data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleExport = () => {
    const filteredData = datas.map((item) => ({
      Departman: item.departman,
      Süreç: item.surec,
      Faaliyet: item.faaliyet,
      Faaliyet_Adımı: item.faaliyet_adimi,
      Tehlike: item.tehlike,
      Zarar: item.zarar,
      Kök_Neden: item.tehlike_kok_nedeni,
      Olasılık: item.olasilik,
      Etki: item.etki,
      Risk_Bolumsi: item.risk_boyutu,
      Risk_Sınıfı: item.risk_sinifi,
      Önlemler: item.onlem,
      Ölçüm_Periyodu: item.olcum_periyodu,
      Kontrol_Ölçümleri: item.kontrol_olcumleri,
    }));
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Risk Analiz Raporu");
    const columnWidths = [
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 200 },
      { wpx: 100 },
      { wpx: 100 },
      { wpx: 150 },
      { wpx: 100 },
      { wpx: 200 },
      { wpx: 150 },
      { wpx: 200 },
    ];

    worksheet["!cols"] = columnWidths;
    XLSX.writeFile(workbook, "RiskAnalizRaporu.xlsx");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Ara`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Ara
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Sıfırla
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Departman Adı",
      dataIndex: "departman",
      key: "departman",
      ...getColumnSearchProps("departman"),
    },
    {
      title: "Süreç",
      dataIndex: "surec",
      key: "surec",
      ...getColumnSearchProps("surec"),
    },
    {
      title: "Faaliyet",
      dataIndex: "faaliyet",
      key: "faaliyet",
      ...getColumnSearchProps("faaliyet"),
    },
    {
      title: "Faaliyet Adımı",
      dataIndex: "faaliyet_adimi",
      key: "faaliyet_adimi",
      ...getColumnSearchProps("faaliyet_adimi"),
    },
    {
      title: "Tehlike",
      dataIndex: "tehlike",
      key: "tehlike",
      ...getColumnSearchProps("tehlike"),
    },
    {
      title: "Zarar",
      dataIndex: "zarar",
      key: "zarar",
      ...getColumnSearchProps("zarar"),
    },
    {
      title: "Kök Neden",
      dataIndex: "tehlike_kok_nedeni",
      key: "tehlike_kok_nedeni",
      ...getColumnSearchProps("tehlike_kok_nedeni"),
    },
    {
      title: "Olasılık",
      dataIndex: "olasilik",
      key: "olasilik",
      ...getColumnSearchProps("olasilik"),
    },
    {
      title: "Etki",
      dataIndex: "etki",
      key: "etki",
      ...getColumnSearchProps("etki"),
    },
    {
      title: "Risk Bolumsi",
      dataIndex: "risk_boyutu",
      key: "risk_boyutu",
      ...getColumnSearchProps("risk_boyutu"),
    },
    {
      title: "Risk Sınıfı",
      dataIndex: "risk_sinifi",
      key: "risk_sinifi",
      ...getColumnSearchProps("risk_sinifi"),

      render: (text) => {
        let color = "";
        if (text === "1") {
          color = "green";
        } else if (text === "2") {
          color = "yellow";
        } else if (text === "3") {
          color = "red";
        }
        return {
          props: {
            style: { background: color },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "Önlemler",
      dataIndex: "onlem",
      key: "onlem",
      ...getColumnSearchProps("onlem"),
    },
    {
      title: "Ölçüm Periyodu",
      dataIndex: "olcum_periyodu",
      key: "olcum_periyodu",
      ...getColumnSearchProps("olcum_periyodu"),
    },
    {
      title: "Kontrol Ölçümleri",
      dataIndex: "kontrol_olcumleri",
      key: "kontrol_olcumleri",
      ...getColumnSearchProps("kontrol_olcumleri"),
    },
    {
      title: "Hedef Silme",
      dataIndex: "sil",
      key: "sil",
      align: "center",
      render: (text, record) => (
        <Button type="primary" onClick={() => handleSil(record._id)}>
          Sil
        </Button>
      ),
    },
  ];

  return (
    <>
      <div>
        <Header />
      </div>
      <div className="Anasayfa flex justify-normal">
        <div className="SolMenu">
          <SolMenu />
        </div>
        <div className="px-6 py-6 flex flex-col">
          <Button
            type="primary"
            style={{ marginBottom: "16px", width: "200px" }} // Buton genişliğini ayarlayın
            onClick={handleExport}
          >
            Excel'e Aktar
          </Button>
          <Table
            dataSource={datas}
            columns={columns}
            bordered
            pagination={false}
            rowClassName={(record) => {
              if (record.risk_sinifi === "1") return "green-row";
              if (record.risk_sinifi === "2") return "yellow-row";
              if (record.risk_sinifi === "3") return "red-row";
              return "";
            }}
          />
        </div>
      </div>
    </>
  );
};

export default RiskAnalizRapor;
