import React, { useCallback, useState, useEffect } from "react";
import Header from "../components/header/Header";
import SolMenu from "../components/header/solmenu/Solmenu";
import { Table, Button } from "antd";
import { useNavigate } from "react-router-dom";
import config from "../components/header/config";
export const UcretYonetimi = () => {
  const navigate = useNavigate();
  const [datas, setDatas] = useState([]);
  const fetchData = useCallback(async (url, setData) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      setDatas(data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  useEffect(() => {
    fetchData(`${config.API_URL}/list/ucretyonetimi`, setDatas);
  }, [fetchData]);
  const columns = [
    {
      title: "Derece",
      dataIndex: "derece",
      key: "derece",
      align: "center",
    },
    {
      title: "Unvan",
      dataIndex: "unvan",
      key: "unvan",
      align: "center",
    },
    {
      title: "Ağırlık",
      dataIndex: "agirlik",
      key: "agirlik",
      align: "center",
    },
    {
      title: "Ana Maaş",
      dataIndex: "anamaas",
      key: "anamaas",
      align: "center",
    },
    {
      title: "Performans",
      dataIndex: "performans",
      key: "performans",
      align: "center",
    },
    {
      title: "Toplam Maaş",
      dataIndex: "toplam_maas",
      key: "toplam_maas",
      align: "center",
    },

    {
      title: "Güncelle",
      dataIndex: "guncelle",
      key: "guncelle",
      align: "center",
      render: (text, record) => (
        <Button type="primary" onClick={() => handleGuncelle(record._id)}>
          Güncelle
        </Button>
      ),
    },
  ];

  const handleGuncelle = async (id) => {
    navigate(`/UcretYonetimi/Guncelle/${id}`);
  };
  return (
    <>
      <div>
        <Header> </Header>
      </div>
      <div className="Anasayfa flex justify-normal">
        <div className="SolMenu">
          <SolMenu />
        </div>
        <div className="px-6 py-6 flex flex-col flex-grow">
          <Table
            dataSource={datas}
            columns={columns}
            bordered
            pagination={false}
          />
          <div className="mt-4 flex justify-start"></div>
        </div>
      </div>
    </>
  );
};
