import React from "react";
import Header from "../components/header/Header";
import SolMenu from "../components/header/solmenu/Solmenu";
import { Form, Input, Button, Select } from "antd";
import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import config from "../components/header/config";
const UcretYonetimiGuncelle = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [value, setValue] = useState("");
  const [unvanlar, setUnvanlar] = useState([]);
  const [Dereceler, setDereceler] = useState([]);

  const fetchData = useCallback(
    async (url, setData) => {
      try {
        const response = await fetch(url);
        const data = await response.json();
        const filteredData = data.filter((item) => item._id === id);
        setValue(filteredData[0]);
        form.setFieldsValue({
          derece: filteredData[0].derece,
          departman: filteredData[0].departman,
          agirlik: filteredData[0].agirlik,
          anamaas: filteredData[0].anamaas,
          performans: filteredData[0].performans,
          toplam_maas: filteredData[0].toplam_maas,
        });
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [id, form]
  );

  useEffect(() => {
    fetchData(`${config.API_URL}/list/ucretyonetimi`, setValue);
  }, [fetchData]);

  const fetchSelect = useCallback(async (url, setData) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      const adlar = data.map((da) => da.ad);
      setData(adlar);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  useEffect(() => {
    fetchSelect(`${config.API_URL}/list/unvan`, setUnvanlar);
    fetchSelect(`${config.API_URL}/list/derece`, setDereceler);
  }, [fetchData]);

  const handleSubmit = async (values) => {
    const updated = values;
    try {
      const response = await fetch(`${config.API_URL}/updateucret/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updated),
      });
      if (response.ok) {
        console.log("Data saved successfully");
        navigate("/UcretYonetimi");
      } else {
        console.error("Failed to save data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleValuesChange = (changedValues, allValues) => {
    const { agirlik, anamaas, performans } = allValues;
    if (agirlik && anamaas && performans) {
      const toplam_maas = agirlik * anamaas * performans;
      form.setFieldsValue({ toplam_maas });
    }
  };

  return (
    <>
      <div>
        <Header />
      </div>
      <div className="Anasayfa flex justify-normal">
        <div className="SolMenu">
          <SolMenu />
        </div>
        <div className="px-6 py-6 flex flex-col flex-grow">
          <Form
            form={form}
            onFinish={handleSubmit}
            onValuesChange={handleValuesChange}
            layout="vertical"
            className="w-full px-3"
          >
            <Form.Item
              label="Derece"
              name="Derece"
              rules={[{ required: true, message: "Lütfen Derece Giriniz" }]}
            >
              <Select placeholder="Derece Giriniz">
                {Dereceler.map((derece, i) => (
                  <Select.Option key={i} value={derece}>
                    {derece}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Unvan"
              name="unvan"
              rules={[{ required: true, message: "Lütfen Unvan Giriniz" }]}
            >
              <Select placeholder="Unvan Giriniz">
                {unvanlar.map((unvan, i) => (
                  <Select.Option key={i} value={unvan}>
                    {unvan}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Ağırlık"
              name="agirlik"
              rules={[
                {
                  required: true,
                  message: "Lütfen Ağırlık Giriniz",
                },
              ]}
            >
              <Input
                placeholder="Ağırlık Giriniz"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  form.setFieldsValue({ agirlik: e.target.value });
                }}
              />
            </Form.Item>

            <Form.Item
              label="Ana Maaş"
              name="anamaas"
              rules={[
                {
                  required: true,
                  message: "Lütfen Ana Maaş Giriniz",
                },
              ]}
            >
              <Input
                placeholder="Ana Maaş Giriniz"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  form.setFieldsValue({ anamaas: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Performans"
              name="performans"
              rules={[
                {
                  required: true,
                  message: "Lütfen Performans Giriniz",
                },
              ]}
            >
              <Input
                placeholder="Performans Giriniz"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  form.setFieldsValue({ performans: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item
              label="Toplam Maaş"
              name="toplam_maas"
              rules={[
                {
                  required: true,
                  message: "Lütfen Toplam Maaş Giriniz",
                },
              ]}
            >
              <Input placeholder="Toplam Maaş Giriniz" value={value} readOnly />
            </Form.Item>

            <Button htmlType="submit" type="primary">
              Kaydet
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default UcretYonetimiGuncelle;
