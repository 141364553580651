import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import SolMenu from "../components/header/solmenu/Solmenu";
import { Pie, Line } from "react-chartjs-2";
import {
  Chart,
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
} from "chart.js";
import config from "../components/header/config";
// Register necessary components
Chart.register(
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale
);

const Istatistikler = () => {
  const [riskCounts, setRiskCounts] = useState([]);
  const [kpiCounts, setKpiCounts] = useState([]);
  const [departmanCounts, setDepartmanCounts] = useState([]);
  const [kontrol, setKontrol] = useState([]);

  const fetchData = async (endpoint, setState) => {
    try {
      const response = await fetch(`${config.API_URL}/count/${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}), // Boş bir JSON nesnesi gönderiliyor
      });

      if (!response.ok) {
        throw new Error("Veri çekme hatası");
      }

      const data = await response.json();
      setState(data);
    } catch (error) {
      console.error("Veri çekme hatası:", error);
    }
  };

  useEffect(() => {
    fetchData("kpi", setKpiCounts);
    fetchData("departman", setDepartmanCounts);
  }, []);

  useEffect(() => {
    const fetchRiskCounts = async () => {
      try {
        const response = await fetch(`${config.API_URL}/countRisk`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}), // Boş bir JSON nesnesi gönderin
        });

        if (!response.ok) {
          throw new Error("Veri çekme hatası");
        }

        const data = await response.json();
        console.log("Risk Counts:", data); // Veriyi kontrol edin
        setRiskCounts(data);
      } catch (error) {
        console.error("Veri çekme hatası:", error);
      }
    };

    fetchRiskCounts();
  }, []);

  useEffect(() => {
    const fetchDepartmanCounts = async () => {
      try {
        const response = await fetch(`${config.API_URL}/departman-puan`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}), // Boş bir JSON nesnesi gönderin
        });

        if (!response.ok) {
          throw new Error("Veri çekme hatası");
        }

        const data = await response.json();
        setKontrol(data);
        console.log(data); // Veriyi kontrol edin
      } catch (error) {
        console.error("Veri çekme hatası:", error);
      }
    };

    fetchDepartmanCounts();
  }, []);

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const colors = kpiCounts.map(() => getRandomColor());

  const dataKpi = {
    labels: kpiCounts.map((kpi) => kpi._id), // KPI kimliklerini döndür
    datasets: [
      {
        data: kpiCounts.map((kpi) => kpi.count), // KPI sayılarını döndür
        backgroundColor: colors, // Dinamik olarak oluşturulan renkler
      },
    ],
  };

  const dataDepartman = {
    labels: departmanCounts.map((departman) => departman._id),
    datasets: [
      {
        data: departmanCounts.map((departman) => departman.count),
        backgroundColor: colors,
      },
    ],
  };

  const dataRisk = {
    labels: riskCounts.map((risk) => risk._id),
    datasets: [
      {
        data: riskCounts.map((risk) => risk.count),
        backgroundColor: colors,
      },
    ],
  };

  const weekMap = {};
  let weekCounter = 1;

  const groupedData = kontrol.reduce(
    (acc, item) => {
      if (!(item.week in weekMap)) {
        weekMap[item.week] = weekCounter++;
      }
      const label = `Week ${weekMap[item.week]}`;
      if (!acc.labels.includes(label)) {
        acc.labels.push(label);
      }

      const deptIndex = acc.departments.indexOf(item.departman);
      if (deptIndex === -1) {
        acc.departments.push(item.departman);
        acc.datasets.push({
          label: item.departman,
          data: Array(acc.labels.length - 1).fill(0), // Önceki haftaları 0 olarak doldur
          borderColor: getRandomColor(),
          backgroundColor: getRandomColor(0.2),
        });
      }
      // İlgili departman ve haftanın indexini bul ve oraya puanı ekle
      const weekIndex = acc.labels.indexOf(label);
      acc.datasets[acc.departments.indexOf(item.departman)].data[weekIndex] =
        item.totalPuan;

      return acc;
    },
    { labels: [], departments: [], datasets: [] }
  );

  // Daha önce eklenmiş haftalara eksik verileri eklemek için her data dizisinin uzunluğunu eşitliyoruz
  groupedData.datasets.forEach((dataset) => {
    while (dataset.data.length < groupedData.labels.length) {
      dataset.data.push(0);
    }
  });

  const dataKontrol = {
    labels: groupedData.labels,
    datasets: groupedData.datasets,
  };

  const optionsLine = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          usePointStyle: true,
          padding: 20,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";
            if (context.parsed.y !== null) {
              label += ": " + context.parsed.y;
            }
            return label;
          },
        },
      },
    },
    layout: {
      padding: {
        top: 10,
        bottom: 10,
      },
    },
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Grafiğin boyutunun değiştirilmesine izin verir
    plugins: {
      legend: {
        position: "top", // Legend'ı üstte konumlandırır
        labels: {
          usePointStyle: true,
          padding: 20,
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.label || "";
            if (context.parsed !== null) {
              label += ": " + context.parsed.toLocaleString(); // Veri formatlaması
            }
            return label;
          },
        },
      },
    },
    layout: {
      padding: {
        top: 10,
        bottom: 10,
      },
    },
  };

  return (
    <>
      <Header />
      <div className="Anasayfa flex justify-normal">
        <div className="SolMenu">
          <SolMenu />
        </div>
        <div className="px-6 py-6 flex flex-col flex-grow">
          <div className="w-full max-w-6xl mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              <div className="flex flex-col items-center">
                <h2 className="text-xl font-semibold mb-2 text-center">
                  Stratejik Hedef Oluşturan KPI Grafiği
                </h2>
                <div className="w-full h-96">
                  <Pie data={dataKpi} options={options} />
                </div>
              </div>
              <div className="flex flex-col items-center">
                <h2 className="text-xl font-semibold mb-2 text-center">
                  Stratejik Hedef Oluşturan Departman Grafiği
                </h2>
                <div className="w-full h-96">
                  <Pie data={dataDepartman} options={options} />
                </div>
              </div>
              <div className="flex flex-col items-center">
                <h2 className="text-xl font-semibold mb-2 text-center">
                  Yüksek Risk Oluşturan Departmanlar Grafiği
                </h2>
                <div className="w-full h-96">
                  <Pie data={dataRisk} options={options} />
                </div>
              </div>
              <div className="flex flex-col items-center">
                <h2 className="text-xl font-semibold mb-2 text-center">
                  Departman Puanları Grafiği
                </h2>
                <div className="w-full h-96">
                  <Line data={dataKontrol} options={optionsLine} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Istatistikler;
