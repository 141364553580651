import React from "react";
import Header from "../../components/header/Header";
import SolMenu from "../../components/header/solmenu/Solmenu";
import { Form, Input, Button } from "antd";
import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import config from "../../components/header/config";
const BolumGuncelle = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [value, setValue] = useState("");

  const fetchData = useCallback(
    async (url, setData) => {
      try {
        const response = await fetch(url);
        const data = await response.json();
        const filteredData = data.filter((item) => item._id === id);
        setValue(filteredData[0].ad);
        form.setFieldsValue({ ad: filteredData[0].ad });
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [id, form]
  );

  useEffect(() => {
    fetchData(`${config.API_URL}/list/bolum`, setValue);
  }, [fetchData]);

  const handleSubmit = async (values) => {
    console.log(values);
    try {
      const response = await fetch(`${config.API_URL}/update/bolum`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: id, value: values.ad }),
      });
      if (response.ok) {
        console.log("Data saved successfully");
        navigate("/Bolum");
      } else {
        console.error("Failed to save data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div>
        <Header />
      </div>
      <div className="Anasayfa flex justify-normal">
        <div className="SolMenu">
          <SolMenu />
        </div>
        <div className="px-6 py-6 flex flex-col flex-grow">
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            className="w-full px-3"
          >
            <Form.Item
              label="Bolum Ad"
              name="ad"
              rules={[
                {
                  required: true,
                  message: "Lütfen Bolum Ad Giriniz",
                },
              ]}
            >
              <Input
                placeholder="Bolum Ad Giriniz"
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                  form.setFieldsValue({ ad: e.target.value });
                }}
              />
            </Form.Item>

            <Button htmlType="submit" type="primary">
              Kaydet
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default BolumGuncelle;
