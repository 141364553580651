import React, { useState, useEffect } from "react";
import { Badge, Image } from "antd";
import {
  HomeOutlined,
  UserOutlined,
  BarChartOutlined,
  LogoutOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import config from "./config";

const Header = () => {
  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await fetch(`${config.API_URL}/list/stratejikhedef`);
        const data = await response.json();
        const name = localStorage.getItem("ad"); // Fetch the name from localStorage
        const filteredData = data.filter((item) => {
          if (name === "Admin") {
            return item.status === "0";
          } else {
            return item.status === "1" || item.status === "-1";
          }
        });

        setNotificationCount(filteredData.length);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("ad");
  };

  return (
    <div className="border-b">
      <header
        className="py-2 px-6 flex justify-between items-center gap-10"
        style={{ height: "80px" }}
      >
        <div className="logo">
          <a href="/Anasayfa" className="flex items-center h-full">
            <Image
              preview={false}
              width={70}
              src="https://yt3.googleusercontent.com/N9nddIhVKO5_bVRCgJEvFTGBfNPNhp8jDO1SMXPP9l9UHo6JQOm4tYIUCuLacrTcCNx98OETRg=s900-c-k-c0x00ffffff-no-rj"
            />
          </a>
        </div>
        <div className="menu-links flex justify-between items-center gap-5 md:static fixed bottom-0 md:w-auto w-screen md:bg-transparent bg-white left-0 md:border-t-0 border-t md:px-0 px-4 py-1">
          <Link
            to={"/Anasayfa"}
            className="menu-link flex flex-col hover:text-[#40a9ff] transition-all"
          >
            <HomeOutlined className="md:text-2xl text-xl px-3" />
            <span className="md:text-xs text-[10px] font-bold">Anasayfa</span>
          </Link>
          <Badge count={notificationCount}>
            <Link
              to={"/Bildirimler"}
              className="menu-link flex flex-col hover:text-[#40a9ff] transition-all"
            >
              <MessageOutlined className="md:text-2xl text-xl px-3" />
              <span className="md:text-xs text-[10px] font-bold">
                Bildirimler
              </span>
            </Link>
          </Badge>
          <Link
            to={"/Istatistikler"}
            className="menu-link flex flex-col hover:text-[#40a9ff] transition-all"
          >
            <BarChartOutlined className="md:text-2xl text-xl px-3" />
            <span className="md:text-xs text-[10px] font-bold">
              İstatislikler
            </span>
          </Link>

          <Link
            onClick={handleLogout}
            to={"/"}
            className="menu-link flex flex-col hover:text-[#40a9ff] transition-all"
          >
            <LogoutOutlined className="md:text-2xl text-xl" />
            <span className="md:text-xs text-[10px] font-bold">Çıkış</span>
          </Link>
        </div>
      </header>
    </div>
  );
};

export default Header;
