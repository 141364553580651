import React from "react";
import Header from "../components/header/Header";
import SolMenu from "../components/header/solmenu/Solmenu";
import { useState, useCallback, useEffect } from "react";

import { Form, Input, Select, Button } from "antd";
import config from "../components/header/config";
function RiskAnaliz() {
  const [form] = Form.useForm();
  const [departmanlar, setDepartmanlar] = useState([]);

  const fetchData = useCallback(async (url, setData) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      const adlar = data.map((da) => da.ad);
      setData(adlar);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  useEffect(() => {
    fetchData(`${config.API_URL}/list/departman`, setDepartmanlar);
  }, [fetchData]);

  const handleSubmit = async (values) => {
    const risk = values;
    try {
      const response = await fetch(`${config.API_URL}/addrisk`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(risk),
      });

      if (response.ok) {
        console.log("Data saved successfully");
        form.resetFields();
      } else {
        console.error("Failed to save data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleValuesChange = (changedValues, allValues) => {
    if ("olasilik" in changedValues || "etki" in changedValues) {
      const { olasilik, etki } = allValues;
      if (olasilik !== undefined && etki !== undefined) {
        const carpim = olasilik * etki;
        let riskSinifi = "";

        if (carpim > 15) {
          riskSinifi = "3"; // YÜKSEK
        } else if (carpim < 9) {
          riskSinifi = "1"; // DÜŞÜK
        } else {
          riskSinifi = "2"; // ORTA
        }

        // Form değerlerini güncelle
        form.setFieldsValue({
          ...allValues,
          risk_sinifi: riskSinifi,
        });
      }
    }
  };

  return (
    <>
      <div>
        <Header> </Header>
      </div>
      <div className="Anasayfa  flex justify-normal">
        <div className="SolMenu">
          <div>
            <SolMenu></SolMenu>{" "}
          </div>
        </div>

        <Form
          onValuesChange={handleValuesChange}
          layout="vertical px-3 "
          className="w-full"
          form={form}
          onFinish={handleSubmit}
        >
          <Form.Item
            label="Departman"
            name="departman"
            rules={[{ required: true, message: "Lütfen Departman Giriniz" }]}
          >
            <Select placeholder="Departman Giriniz">
              {departmanlar.map((departman, i) => (
                <Select.Option key={i} value={departman}>
                  {departman}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Süreç"
            name="surec"
            rules={[{ required: true, message: "Lütfen Süreç Giriniz" }]}
          >
            <Input placeholder="Süreç Giriniz" />
          </Form.Item>
          <Form.Item
            label="Faaliyet"
            name="faaliyet"
            rules={[{ required: true, message: "Lütfen Faaliyet Giriniz" }]}
          >
            <Input placeholder="Faaliyet Giriniz" className="w-full" />
          </Form.Item>
          <Form.Item
            label="Faaliyet Adımı"
            name="faaliyet_adimi"
            rules={[
              { required: true, message: "Lütfen Faaliyet Adımı Giriniz" },
            ]}
          >
            <Input placeholder="Faaliyet Adımı Giriniz" />
          </Form.Item>

          <Form.Item
            label="Tehlike"
            name="tehlike"
            rules={[{ required: true, message: "Lütfen Tehlike Giriniz" }]}
          >
            <Input placeholder="Tehlike Giriniz" />
          </Form.Item>
          <Form.Item
            label="Zarar"
            name="zarar"
            rules={[{ required: true, message: "Lütfen Zarar Giriniz" }]}
          >
            <Input placeholder="Zarar Giriniz" />
          </Form.Item>
          <Form.Item
            label="Tehlike Kök Nedeni"
            name="tehlike_kok_nedeni"
            rules={[
              { required: true, message: "Lütfen Tehlike Kök Nedeni Giriniz" },
            ]}
          >
            <Input placeholder="Tehlike Kök Nedeni Giriniz" />
          </Form.Item>

          <Form.Item
            label="Olasılık"
            name="olasilik"
            rules={[{ required: true, message: "Lütfen Olasılık Giriniz" }]}
          >
            <Select placeholder="Olasılık Giriniz">
              <Select.Option value="1">Nadir-Puan 1</Select.Option>
              <Select.Option value="2">Arasıra-Puan 2</Select.Option>
              <Select.Option value="3">Genellikle-Puan 3</Select.Option>
              <Select.Option value="4">Sık Sık-Puan 4</Select.Option>
              <Select.Option value="5">Sürekli-Puan 5</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Etki"
            name="etki"
            rules={[{ required: true, message: "Lütfen Etki Giriniz" }]}
          >
            <Select placeholder="Etki Giriniz">
              <Select.Option value="1">0₺-1.000₺</Select.Option>
              <Select.Option value="2">1.001₺-10.000₺</Select.Option>
              <Select.Option value="3">10.001₺-25.000₺</Select.Option>
              <Select.Option value="4">25.001₺-50.000₺</Select.Option>
              <Select.Option value="5">50.000₺- ve Üzeri</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Risk Boyutu"
            name="risk_boyutu"
            rules={[{ required: true, message: "Lütfen Risk Boyutu Giriniz" }]}
          >
            <Input placeholder="Risk Boyutu Giriniz" />
          </Form.Item>

          <Form.Item
            label="Risk Sınıfı"
            name="risk_sinifi"
            rules={[{ required: true, message: "Lütfen Risk Sınıfı Giriniz" }]}
          >
            <Select placeholder="Risk Sınıfı Giriniz" disabled>
              <Select.Option value="1">DÜŞÜK</Select.Option>
              <Select.Option value="2">ORTA</Select.Option>
              <Select.Option value="3">YÜKSEK</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Önlem"
            name="onlem"
            rules={[{ required: true, message: "Lütfen Önlem Giriniz" }]}
          >
            <Input placeholder="Önlem Giriniz" />
          </Form.Item>

          <Form.Item
            label="Ölçüm Periyodu"
            name="olcum_periyodu"
            rules={[
              { required: true, message: "Lütfen Ölçüm Periyodu Giriniz" },
            ]}
          >
            <Input placeholder="Ölçüm Periyodu Giriniz" />
          </Form.Item>

          <Form.Item
            label="Kontrol Ölçümleri"
            name="kontrol_olcumleri"
            rules={[
              { required: true, message: "Lütfen Kontrol Ölçümleri Giriniz" },
            ]}
          >
            <Input placeholder="Kontrol Ölçümleri Giriniz" />
          </Form.Item>

          <Button type="primary" htmlType="submit">
            Kaydet
          </Button>
        </Form>
      </div>
    </>
  );
}

export default RiskAnaliz;
