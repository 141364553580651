import React from "react";
import Header from "../components/header/Header";
import SolMenu from "../components/header/solmenu/Solmenu";
import { Table, Button } from "antd";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import config from "../components/header/config";

export const Bildirimler = () => {
  const [datas, setDatas] = useState([]);
  const [ad, setAd] = useState(""); // ad için state tanımlıyoruz
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const storedAd = localStorage.getItem("ad");
    setAd(storedAd); // ad değerini state'e set ediyoruz
    if (!token) {
      navigate("/");
    }
  }, []);

  const fetchData = useCallback(
    async (url, setData) => {
      try {
        const response = await fetch(url);
        const data = await response.json();
        if (ad === "Admin") {
          const filteredData = data.filter((item) => item.status === "0");
          setDatas(filteredData);
        } else {
          const filteredData = data.filter(
            (item) =>
              item.status === "1" ||
              (item.status === "-1" && item.sorumlu === ad)
          );
          setDatas(filteredData);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    },
    [ad]
  ); // fetchData bağımlılığına ad'ı ekliyoruz

  useEffect(() => {
    fetchData(`${config.API_URL}/list/stratejikhedef`, setDatas);
  }, [fetchData]);

  const columns = [
    {
      title: "KPI",
      dataIndex: "kpi",
      key: "kpi",
      align: "center",
    },
    {
      title: "Departman Adı",
      dataIndex: "departman",
      key: "departman",
      align: "center",
    },
    {
      title: "Hedef Türü",
      dataIndex: "hedef_turu",
      key: "hedef_turu",
      align: "center",
    },
    {
      title: "Mevcut Durum",
      dataIndex: "mevcut_durum",
      key: "mevcut_durum",
      align: "center",
    },
    {
      title: "Hedef Yüzdesi",
      dataIndex: "hedef_yuzde",
      key: "hedef_yuzde",
      align: "center",
    },
    {
      title: "Açıklama",
      dataIndex: "mevcut_durum_aciklama",
      key: "mevcut_durum_aciklama",
      align: "center",
    },
    {
      title: "Aksiyon",
      dataIndex: "aksiyon",
      key: "aksiyon",
      align: "center",
    },
    {
      title: "Sorumlu",
      dataIndex: "sorumlu",
      key: "sorumlu",
      align: "center",
    },
    {
      title: "Maliyeti",
      dataIndex: "maliyet",
      key: "maliyet",
      align: "center",
    },
    {
      title: "Termin Tarihi",
      dataIndex: "date",
      key: "date",
      align: "center",
    },
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
      align: "center",
    },
    {
      title: "Güncelle",
      dataIndex: "guncelle",
      key: "guncelle",
      align: "center",
      render: (text, record) => (
        <Button type="primary" onClick={() => handleGuncelle(record._id)}>
          Güncelle
        </Button>
      ),
    },
    {
      title: "Onayla",
      dataIndex: "onay",
      key: "onay",
      align: "center",
      render: (text, record) =>
        ad === "Admin" || (ad !== "Admin" && record.status === "1") ? (
          <Button
            type="primary"
            onClick={() =>
              handleOnayla(record._id, record.status, record.sorumlu)
            }
          >
            Onayla
          </Button>
        ) : null,
    },
    {
      title: "Reddet",
      dataIndex: "reddet",
      key: "reddet",
      align: "center",
      render: (text, record) =>
        ad === "Admin" || (ad !== "Admin" && record.status === "-1") ? (
          <Button
            type="primary"
            onClick={() =>
              handleReddet(record._id, record.status, record.sorumlu)
            }
          >
            Reddet
          </Button>
        ) : null,
    },
  ];

  const handleGuncelle = (id) => {
    navigate(`/StratejikHedef/Guncelle/${id}`);
  };

  const handleOnayla = async (id, status, sorumlu) => {
    let number;
    if (ad === "Admin") {
      if (sorumlu === "Admin") {
        number = 2;
      } else {
        if (status === "0") {
          number = "1";
        } else if (status === "1") {
          number = "2";
        }
      }
    } else {
      number = "2";
    }

    try {
      const response = await fetch(`${config.API_URL}/updatestrat/${number}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
      });

      if (response.ok) {
        console.log("Data saved successfully");
        // Veri başarılı bir şekilde kaydedildiğinde yapılacak işlemler
      } else {
        console.error("Failed to save data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleReddet = async (id, status, sorumlu) => {
    let number;
    if (ad === "Admin") {
      if (sorumlu === "Admin") {
        number = "-2";
      } else {
        number = "-1";
      }
    } else {
      number = "-2";
    }
    try {
      const response = await fetch(`${config.API_URL}/updatestrat/${number}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
      });

      if (response.ok) {
        console.log("Data rejected successfully");
        // Veri reddedildiğinde yapılacak işlemler
      } else {
        console.error("Failed to reject data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div>
        <Header />
      </div>
      <div className="Anasayfa flex justify-normal">
        <div className="SolMenu">
          <SolMenu />
        </div>
        <div className="px-6 py-6 flex flex-col flex-grow">
          <Table
            dataSource={datas}
            columns={columns}
            bordered
            pagination={false}
          />
        </div>
      </div>
    </>
  );
};
