import React, { useEffect, useState, useCallback } from "react";
import Header from "../components/header/Header";
import SolMenu from "../components/header/solmenu/Solmenu";
import { Table, Button, Input } from "antd";
import { useNavigate } from "react-router-dom";
import Highlighter from "react-highlight-words"; // Highlighter import
import config from "../components/header/config";
import { SearchOutlined } from "@ant-design/icons";
export const KontrolNoktalari = () => {
  const navigate = useNavigate();
  const [datas, setDatas] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const fetchData = useCallback(async (url, setData) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      setDatas(data);
    } catch (error) {
      console.error("Error:", error);
    }
  }, []);

  useEffect(() => {
    fetchData(`${config.API_URL}/list/kontrolnoktasi`, setDatas);
  }, [fetchData]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Ara`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Ara
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Sıfırla
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: "Departman Adı",
      dataIndex: "departman",
      key: "departman",
      align: "center",
      ...getColumnSearchProps("departman"),
    },
    {
      title: "Kontrol Noktası",
      dataIndex: "tehlike",
      key: "tehlike",
      align: "center",
      ...getColumnSearchProps("tehlike"),
    },
    {
      title: "Ağırlık",
      dataIndex: "agirlik",
      key: "agirlik",
      align: "center",
      ...getColumnSearchProps("agirlik"),
    },
    {
      title: "Durum",
      dataIndex: "durum",
      key: "durum",
      align: "center",
      ...getColumnSearchProps("durum"),
    },
    {
      title: "Puan",
      dataIndex: "puan",
      key: "puan",
      align: "center",
      ...getColumnSearchProps("puan"),
    },
    {
      title: "Açıklama",
      dataIndex: "aciklama",
      key: "aciklama",
      align: "center",
      ...getColumnSearchProps("aciklama"),
    },
    {
      title: "Güncelle",
      dataIndex: "guncelle",
      key: "guncelle",
      align: "center",
      render: (text, record) => (
        <Button type="primary" onClick={() => handleGuncelle(record._id)}>
          Güncelle
        </Button>
      ),
    },
    {
      title: "Sil",
      dataIndex: "Sil",
      key: "Sil",
      align: "center",
      render: (text, record) => (
        <Button type="primary" onClick={() => handleSil(record._id)}>
          Sil
        </Button>
      ),
    },
  ];

  const handleGuncelle = async (id) => {
    navigate(`/KontrolNoktalari/Guncelle/${id}`);
  };

  const handleSil = async (id) => {
    try {
      const response = await fetch(`${config.API_URL}/delete/kontrolnoktasi`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
      });

      if (response.ok) {
        console.log("Data saved successfully");
      } else {
        console.error("Failed to save data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div>
        <Header> </Header>
      </div>
      <div className="Anasayfa flex justify-normal">
        <div className="SolMenu">
          <SolMenu />
        </div>
        <div className="px-6 py-6 flex flex-col flex-grow">
          <Table
            dataSource={datas}
            columns={columns}
            bordered
            pagination={false}
          />
          <div className="mt-4 flex justify-start"></div>
        </div>
      </div>
    </>
  );
};
