import React from "react";
import { Button, Form, Input, Image } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import config from "../components/header/config";
const Giris = () => {
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      const response = await fetch(`${config.API_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ad: values.ad,
          sifre: values.sifre,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      console.log(data);
      localStorage.setItem("id", data.id);
      localStorage.setItem("ad", data.ad);
      localStorage.setItem("rol", data.rol);
      localStorage.setItem("token", data.token);
      navigate("/Anasayfa");
    } catch (error) {
      console.error("Failed:", error.message);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-200 px-4 sm:px-6 lg:px-8">
      <Form
        name="basic"
        className="bg-white p-4 sm:p-6 rounded-lg shadow-md max-w-md w-full"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="flex flex-col items-center mb-4">
          <Image
            preview={false}
            width={200}
            src="https://yt3.googleusercontent.com/N9nddIhVKO5_bVRCgJEvFTGBfNPNhp8jDO1SMXPP9l9UHo6JQOm4tYIUCuLacrTcCNx98OETRg=s900-c-k-c0x00ffffff-no-rj"
          />
          <h1 className="text-2xl font-bold mb-2">HOŞ GELDİNİZ</h1>
          <p className="text-gray-600 mb-4">Lütfen Bilgilerinizi Giriniz</p>
        </div>
        <Form.Item
          name="ad"
          rules={[
            {
              required: true,
              message: "Lütfen Kullanıcı Adı Giriniz",
            },
          ]}
        >
          <Input
            className="w-full py-2 px-3 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500"
            prefix={<UserOutlined className="text-gray-400" />}
            placeholder="Kullanıcı Adı"
          />
        </Form.Item>

        <Form.Item
          name="sifre"
          rules={[
            {
              required: true,
              message: "Lütfen Şifre Giriniz",
            },
          ]}
        >
          <Input.Password
            className="w-full py-2 px-3 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500"
            prefix={<LockOutlined className="text-gray-400" />}
            placeholder="Şifre"
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="w-full">
            Giriş Yap
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Giris;
